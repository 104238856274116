<template>
  <div
    ref="sectionRef"
    class="px-page grid-default gap-y-4 py-10 md:gap-y-0 md:py-20"
    :class="{
      'bg-neutral-50': data.style === 'Gray',
      'bg-purple-100': data.style === 'Purple' && isSectionVisible
    }"
  >
    <div
      class="col-span-12 md:col-span-5"
      :class="[
        {
          'md:col-start-2': data.mediaPosition === 'Left',
          'md:order-2 md:col-start-7': data.mediaPosition === 'Right'
        }
      ]"
    >
      <NuxtLink
        :to="
          data.link[0].internalLink !== null
            ? (localePathByType(
                data?.link[0].internalLink!.__typename as string,
                data?.link[0].internalLink!.slug as string,
                data?.link[0].internalLink!.__typename === ('ArticleRecord' as string) &&
                  data?.link[0].internalLink!.category.slug
              ) as string)
            : (data.link[0].externalLink as string)
        "
        :target="data.link[0].externalLink ? '_blank' : undefined"
      >
        <Picture
          loading="lazy"
          class="aspect-5/4"
          clipType="rounded"
          clipDirection="top"
          :data="data?.image!"
          sizes="(min-width: 1000px) 39.65vw, calc(100vw - 32px)"
        />
      </NuxtLink>
    </div>
    <div
      class="col-span-12 md:col-span-4 md:flex md:flex-col md:justify-between"
      :class="[
        {
          'md:order-1 md:col-start-8': data.mediaPosition === 'Left',
          'md:order-1 md:col-start-2': data.mediaPosition === 'Right'
        }
      ]"
    >
      <div>
        <Heading v-if="data.title" generic :size="3" class="mb-4 md:mb-8">{{ data.title }}</Heading>
        <Type v-if="data.html" :level="2" class="mb-16 max-w-[590px]">
          <div v-html="data.html"></div>
        </Type>
      </div>
      <div>
        <Type v-if="data.htmlLow" :level="2" class="mb-8 max-w-[590px] md:mb-10">
          <div v-html="data.htmlLow"></div>
        </Type>
        <div v-if="Array.isArray(data.link) && data.link.length">
          <Button
            :label="data.link[0].label"
            :to="
              data.link[0].internalLink !== null
                ? localePathByType(
                    data?.link[0].internalLink!.__typename as string,
                    data?.link[0].internalLink!.slug as string,
                    data?.link[0].internalLink!.__typename === ('ArticleRecord' as string) &&
                      data?.link[0].internalLink!.category.slug
                  )
                : data.link[0].externalLink
            "
            :target="data.link[0].externalLink ? '_blank' : undefined"
            variant="link"
            class="font-medium"
            :icon="{
              name: 'circle-chevron-right-general',
              className: 'w-8px h-8px'
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SectionCtaRecord } from '~/types/generated'

const sectionRef = ref<HTMLElement | null>(null)
const isSectionVisible = ref(false)

const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Object as PropType<SectionCtaRecord>,
    default: () => {}
  }
})

const { stop } = useIntersectionObserver(sectionRef, ([{ isIntersecting }], _observerElement) => {
  if (isIntersecting) {
    isSectionVisible.value = true
  }
})

onUnmounted(() => {
  stop()
})
</script>
